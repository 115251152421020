import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import { useNavigate } from "react-router-dom";

function QRCodeScanner() {
  const [result, setResult] = useState(null);
  const navigate = useNavigate();

  const handleScan = (data) => {
    if (data) {
      setResult(data.text);
      console.log("data is ", data.text);      
      // Assuming the QR code data is a URL, you can redirect to it.
      window.open(data.text);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div>
      <QrReader
        delay={300}
        onResult={handleScan}
        constraints={{
          facingMode: 'environment'
      }}
        style={{ width: "100%" }}
      />
    </div>
  );
}

export default QRCodeScanner;
