import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Dashboard from "../../components/Dashboard/Dashboard";
import { URL } from "../../constants/backend";

function MarkStudentAttendance() {
  const location = useLocation();

  // Access the query parameter from the location object
  const queryParams = new URLSearchParams(location.search);
  const dateTime = queryParams.get("datetime");
  const sessionId = queryParams.get("session_id");
  const classId = queryParams.get("class_id");
  console.log("query parameters are", dateTime);
  // console.log("myqueryparam is ",myQueryParam);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const effectRan = useRef(false);

  async function fetchData() {
    try {
      let response = await axios.put(
        URL+"/user/studentMarkAttendance",
        {
          dateTime,
          sessionId,
          classId,
        },
        { withCredentials: true }
      );
      setMessage(response.data["message"]);
    } catch (e) {
      console.error("Error fetching data:", e);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    if (!effectRan.current) {
      fetchData();
    }
    return () => (effectRan.current = true);
  }, []);

  return (
    <Dashboard>
      <p>{message}</p>
    </Dashboard>
  );
}
export default MarkStudentAttendance;
