// import React, {useState} from 'react'

import profile_pic from "../../assets/profile_pic.svg";
import classes from "./Header.module.css";

function Header(props) {
  return (
    <div className={classes.header}>
      <div>
        <img className={classes.homeImg} src={profile_pic} alt="" />
      </div>
      <h1>{props.children}</h1>
      {/* <div>
        <img src={profile_pic} alt="" />
      </div> */}
    </div>
  );
}

export default Header;
