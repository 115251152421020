import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import axios from "axios";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import Dashboard from "../../components/Dashboard/Dashboard";

const CumulativeAttendancePage = () => {
  const { state } = useLocation();
  const classId = state.cId;
  const baseUrl = "https://api.attendeaze.in"
  console.log("state is id ", classId);
  const [classData, setClassData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const downloadCSV = () => {
    const csvData = [];

    // Add headers
    const headers = [
      "Student Name",
      "Roll Number",
      ...classData.map((session) => session.session_name),
    ];
    csvData.push(headers);

    // Add data
    classData[0].values.forEach((student) => {
      const rowData = [
        student.name,
        student.roll_no,
        ...classData.map((session) => {
          const sessionData = session.values.find(
            (s) => s.roll_no === student.roll_no
          );
          return sessionData ? sessionData.status : "";
        }),
      ];
      csvData.push(rowData);
    });

    // Convert to CSV format
    const csv = Papa.unparse(csvData);

    // Create a blob and initiate the download
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", "attendance.csv");
    link.click();
  };

  const customSortByCreatedAt = (a, b) => {
    const dateA = new Date(a.created_at);
    const dateB = new Date(b.created_at);
    return dateA - dateB; // Sort in reverse order
  }

  const getClasses = async () => {
    // dispatch(loadingActions.showLoading())
    console.log("class id is ", classId);
    const res = await axios.get(
      baseUrl+"/api/getSessionsData/" + classId,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    // dispatch(loadingActions.hideLoading());
    console.log("response data is ", res.data.data.attendance);
    const sortedData = res.data.data.attendance.slice(); // Create a copy of the data array
    sortedData.sort(customSortByCreatedAt);
    if (res.status === 200) {
      setClassData(sortedData);
      setIsLoading(false);
    } else {
      toast.error("Cannot fetch session data");
      setIsLoading(false);
    }
    console.log(res);
  };

  // Calculate session-wise percentage
  const sessionWisePercentage = classData.map((session) => {
    const presentCount = session.values.filter(
      (student) => student.status === "P"
    ).length;
    const totalCount = session.values.length;
    return ((presentCount / totalCount) * 100).toFixed(2); // Calculate percentage
  });

  // Calculate student-wise percentage
  const studentWisePercentage = classData[0]?.values.map((student) => {
    const presentCount = classData.reduce((total, session) => {
      const sessionData = session.values.find(
        (s) => s.roll_no === student.roll_no
      );
      return sessionData && sessionData.status === "P" ? total + 1 : total;
    }, 0);
    const totalCount = classData.length;
    return ((presentCount / totalCount) * 100).toFixed(2); // Calculate percentage
  });

  useEffect(() => {
    getClasses();
  },[]);

  return (
    <Dashboard>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <h1>Attendance Table</h1>
          <Button variant="contained" color="primary" onClick={downloadCSV}>
            Download CSV
          </Button>
          
          <TableContainer component={Paper}>
          {classData.length === 0 ? (
  <TableRow>
    <TableCell align="center">
      No data available
    </TableCell>
  </TableRow>
) : (
  <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Student Name</TableCell>
                  <TableCell>Roll Number</TableCell>
                  {classData.map((session) => (
                    <TableCell key={session._id.$oid}>
                      {session.session_name}
                    </TableCell>
                  ))}
                  <TableCell>Session Attendance %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {classData.length === 0 ? (
 <h3 style={{ textAlign: 'center' }}>No data available</h3>

) : (
  classData[0].values.map((student, index) => (
    <TableRow key={student._id.$oid}>
      <TableCell>{student.name}</TableCell>
      <TableCell>{student.roll_no}</TableCell>
      {classData.map((session) => (
        <TableCell key={session._id.$oid}>
          {session.values.find(
            (s) => s.roll_no === student.roll_no
          )?.status || ""}
        </TableCell>
      ))}
      <TableCell>{studentWisePercentage[index]}%</TableCell>
    </TableRow>
  ))
)}
                <TableRow>
                  <TableCell>Session %</TableCell>
                  <TableCell></TableCell>
                  {sessionWisePercentage.map((percentage, index) => (
                    <TableCell key={index}>{percentage}%</TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
)}
            {/* <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Student Name</TableCell>
                  <TableCell>Roll Number</TableCell>
                  {classData.map((session) => (
                    <TableCell key={session._id.$oid}>
                      {session.session_name}
                    </TableCell>
                  ))}
                  <TableCell>Session Attendance %</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {classData.length === 0 ? (
  <TableRow>
    <TableCell align="center">
      No data available
    </TableCell>
  </TableRow>
) : (
  classData[0].values.map((student, index) => (
    <TableRow key={student._id.$oid}>
      <TableCell>{student.name}</TableCell>
      <TableCell>{student.roll_no}</TableCell>
      {classData.map((session) => (
        <TableCell key={session._id.$oid}>
          {session.values.find(
            (s) => s.roll_no === student.roll_no
          )?.status || ""}
        </TableCell>
      ))}
      <TableCell>{studentWisePercentage[index]}%</TableCell>
    </TableRow>
  ))
)}
                <TableRow>
                  <TableCell>Session %</TableCell>
                  <TableCell></TableCell>
                  {sessionWisePercentage.map((percentage, index) => (
                    <TableCell key={index}>{percentage}%</TableCell>
                  ))}
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table> */}
          </TableContainer>
        </div>
      )}
    </Dashboard>
  );
};

export default CumulativeAttendancePage;
