import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../../assets/logo.png";
import classes from "./EmailVerification.module.css";
import {Button,TextField, InputAdornment } from '@mui/material';
import React, { useState, useEffect } from 'react';


import { URL } from "../../constants/backend";

const EmailVerification = () => {
  const [email, setEmail] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const navigate = useNavigate();

  const [inpValid, setInpValid] = useState({
    email: false,
  });

  const validateEmail = (email) => {
    // You can use a regular expression or any validation logic here
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return regex.test(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setEmailIsValid(validateEmail(newEmail));
  };


  const checkEmail = (event) => {
    setEmail(event.target.value);

    let currVal = email;
    if (!currVal.includes("@gmail.com")) {
      setInpValid({
        ...inpValid,
        email: false,
      });
    } else {
      setInpValid({
        ...inpValid,
        email: true,
      });
    }
  };

  let emailErr = () => {
    if (email.includes("@") && !email.includes("@gmail.com")) {
      return <p>Only fms.edu domain allowed</p>;
    } else if (!email.includes("@") && email.length !== 0) {
      return <p>Invalid Email Format</p>;
    }
  };


  const sendEmail = async () => {
    setIsButtonDisabled(true); // Disable the button when clicked
    setTimeout(() => {
      setIsButtonDisabled(false); // Enable the button after 3 seconds
    }, 3000);
    const res = await axios
      .post(`${URL}/email/sendEmail`, {
        email: email,
      })
      .then((res) => {
        console.log(res);
        if (res.status > 199 && res.status < 300) {
          console.log("data is ");
          console.log(res.data.data);
          navigate("/register/otp-verify", { state: res.data.data });
        }
      });
      
  };

  useEffect(() => {
    // Disable the button initially if the email is not valid
    setIsButtonDisabled(!emailIsValid);
  }, [emailIsValid]);


  return (
    <>
      <div className="brand" style={{ marginTop: "1rem" }}>
        <div className="brand--logo">
          <Link to="/">
            <img src={logo} alt="Logo here" />
          </Link>
          <Link to="/">Attend-Eaze</Link>
        </div>
      </div>
      <div className={classes.center}>
      <h2>SignUp</h2>
        <h4>Email Verification</h4>
        <div className={classes.txt_field}>
          <input
            onChange={handleEmailChange}
            value={email}
            error={!emailIsValid}
            helperText={!emailIsValid ? 'Invalid email format' : ''}
            type="text"
            required
          />
          <label>Email</label>
        </div>
        {/* <div className={classes.pass}>Forgot Password</div> */}
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={sendEmail}
          style = {{textTransform:"none"}}
          disabled={isButtonDisabled}
        >
          Verify Email
        </Button>
      </div>
    </>
  );
};

export default EmailVerification;
