import React from "react";
import classes from "./SessionCard.module.css";

function SessionCard(props) {
  return (
    <div className={classes.card_container}>
      <div className={classes.card}>
        <p className={classes.class_name}>{props.sessionName}</p>
        <p className={classes.teacher_name}>{props.sessionPecentage}</p>
      </div>
    </div>
  );
}

export default SessionCard;
