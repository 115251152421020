import axios from "axios";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  duration,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import Papa from "papaparse";
import { URL } from "../../constants/backend";

export default function AddClassModal(props) {
  const handleStudentDownload = () => {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href =
      "https://sacrosanitydata.s3.ap-south-1.amazonaws.com/student_template.csv";
    link.download = "student.csv";
    link.click();
  };

  const [studentsCsvData, setStudentsCsvData] = useState([]);
  const [csvValid, setCsvValid] = useState({ valid: false, message: "" });
  const [fileUploaded, setFileUploaded] = useState(false);

  // const classes = useStyles();
  const cnameRef = useRef();
  const subnameRef = useRef();
  const sectionameRef = useRef();
  const stdRef = useRef();
  const navigate = useNavigate();

  const handleStudentFileUpload = (event) => {
    const file = event.target.files[0];
    console.log("file is ");
    console.log(file);
    if (file) {
      Papa.parse(file, {
        header: true, // Assuming the first row contains headers
        dynamicTyping: true, // Automatically detect data types
        complete: (result) => {
          setFileUploaded(true);
          const headers = result.meta.fields;
          const dataRows = result.data;

          // Check if the required headers are present
          const requiredHeaders = ["Email_id", "Name", "Roll_No"];
          const missingHeaders = requiredHeaders.filter(
            (header) => !headers.includes(header)
          );

          // Check if there is at least one data row
          console.log("datarows are", dataRows);
          if (dataRows.length < 1) {
            setCsvValid({
              valid: false,
              message: "CSV template is incorrect. No data rows found.",
            });
            // setIsCsvValid(false);
            console.error("CSV template is incorrect. No data rows found.");
            // You can use your preferred method to display a toast message here
            // Example: displayToastMessage('CSV template is incorrect. No data rows found.');
          } else if (missingHeaders.length === 0) {
            // Headers are correct, set the data
            setCsvValid({ valid: true, message: "" });
            console.log(dataRows);
            setStudentsCsvData(result.data.slice(0, -1));
          } else {
            setCsvValid({
              valid: false,
              message: "CSV template is incorrect. Missing headers: ",
              missingHeaders,
            });
            // Headers are incorrect, show a toast message
            console.error(
              "CSV template is incorrect. Missing headers: ",
              missingHeaders
            );
            // You can use your preferred method to display a toast message here
            // Example: displayToastMessage('CSV template is incorrect. Missing headers: ' + missingHeaders.join(', '));
          }
        },
        error: (error) => {
          console.error(error.message);
        },
      });
    }
  };

  const submitHandler = async (e) => {
    if (csvValid.valid) {
      e.preventDefault();

    const cname = cnameRef.current.value;
    const subname = subnameRef.current.value;
    const sectionname = sectionameRef.current.value;
    // const teach = teachRef.current.files[0];

    let formData = new FormData();

    const data = { cname, subname, sectionname, studentsCsvData };

    formData.append("className", cname);
    formData.append("subName", subname);
    formData.append("sectionName", sectionname);
    formData.append("students", studentsCsvData);

    // console.log(stds);

    const res = await axios.post(URL+"/auth/addClass", data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });

    console.log("REs :-");
    console.log(res);

    if (res.data.success) {
      toast.success(res.data.message);
      props.closeModal();
      window.location.reload();
    } else {
      toast.error(res.data.message);
    }

      navigate("/");
      props.closeModal();
    } else if (!fileUploaded) {
      toast.error("CSV template is incorrect. No data rows found.", {
        duration: 2000,
      });
    } else {
      toast.error(csvValid.message, { duration: 2000 });
    }
    navigate("/");
    props.closeModal();
  };

  return (
    <Container>
      <Grid containerZ spacing={3}>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5">Add Class</Typography>
            <form onSubmit={submitHandler}>
              <Box sx={{ mt: 2 }}>
                <InputLabel htmlFor="className">Class Name</InputLabel>
                <TextField
                  inputRef={cnameRef}
                  variant="outlined"
                  fullWidth
                  id="className"
                  name="className"
                  required
                  sx={{ width: "100%" }}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <InputLabel htmlFor="subName">Subject</InputLabel>
                <TextField
                  inputRef={subnameRef}
                  variant="outlined"
                  fullWidth
                  id="subName"
                  name="subName"
                  required
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <InputLabel htmlFor="sectionName">Section</InputLabel>
                <TextField
                  inputRef={sectionameRef}
                  variant="outlined"
                  fullWidth
                  id="sectionName"
                  name="sectionName"
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <InputLabel>Add Students</InputLabel>
                <input
                  ref={stdRef}
                  type="file"
                  accept=".csv"
                  name="students"
                  onChange={(e) => handleStudentFileUpload(e)}
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button
                  type="button"
                  variant="contained"
                  color="grey"
                  onClick={handleStudentDownload}
                >
                  Download Student template
                </Button>
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button type="submit" variant="contained" color="primary">
                  Submit
                </Button>
              </Box>
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
