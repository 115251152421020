import { Box, Button, Container, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import QRCode from "react-qr-code";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import AddClassModal from "../../components/AddClassModal/AddClassModal";
import Dashboard from "../../components/Dashboard/Dashboard";
import Modal from "../../components/Modal/Modal";
import { URL } from "../../constants/backend";
import { loadingActions } from "../../store/loadingSlice";

export default function TeacherDashboard() {
  const navigate = useNavigate();
  const [classId, setClassId] = useState("");
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [sessionId, setSessionId] = useState("");

  const user = useSelector((state) => state.user.user);
  const [teachClasses, setTeachClasses] = useState([]);
  const [stateAddClass, setstateAddClass] = useState({
    display: false,
  });

  const [showQR, setShowQr] = useState({
    show: false,
    qr: undefined,
  });

  const closeModal = () => {
    const confirmed = window.confirm(
      "Are you sure you want to end attendance?"
    );

    if (confirmed) {
      // Handle "Yes" action here
      setShowQr({
        show: false,
        qr: "",
      });
    } else {
    }
  };

  const dispatch = useDispatch();

  const getClasses = async () => {
    try {
      dispatch(loadingActions.showLoading());
      const res = await axios.get(URL + "/api/getTeacherClasses", {
        withCredentials: true,
      });
      dispatch(loadingActions.hideLoading());

      const temp = res.data.data.filter((cls) => {
        let ret = false;
        cls.teachers.forEach((std) => {
          if (std.email_id === user.email) {
            ret = true;
          }
        });
        return ret;
      });

      setTeachClasses(temp);
    } catch (error) {
      dispatch(loadingActions.hideLoading());
      console.log("Error in fetching Classes:");
      console.log(error);
    }
  };

  const handleManualAttendance = async (classId) => {
    setClassId(classId);
    console.log(classId);
    const res = await axios.get(URL + "/auth/generateQrCode/" + classId, {
      withCredentials: true,
    });
    console.log("response is ", res.data.data);
    if (res.status === 200) {
      if (res.data.success === true) {
        setSessionId(res.data.data);
        console.log("session id is", sessionId);
        toast.success("Session generated");
      } else {
        toast.error("Cannot generate session");
      }
    }
    const url = "/markAttendancePage/";
    navigate(url, { state: { cId: classId, sId: res.data.data } });
  };

  const handleCumulativeAttendance = (classId) => {
    setClassId(classId);
    console.log("cumumaltive class id is ", classId);
    const url = "/attendance/" + classId;
    navigate(url, { state: { cId: classId } });
  };

  const generateQrCode = async (classId) => {
    setClassId(classId);
    const res = await axios.get(URL + "/auth/generateQrCode/" + classId, {
      withCredentials: true,
    });
    console.log(res.data);
    if (res.status === 200) {
      if (res.data.success === true) {
        let qrString = classId;
        setShowQr({
          show: true,
          qr: qrString,
        });
        setSessionId(res.data.data);
        toast.success("QR generated");
      } else {
        toast.error("Cannot generate QR");
      }
    } else {
      toast.error("Some internal error occurred. Please try again.");
    }
  };

  useEffect(() => {
    getClasses();
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 10000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function AddClassModalFunc() {
    if (stateAddClass.display) {
      return (
        <Modal closeModal={closeAddClassModal}>
          <AddClassModal closeModal={closeAddClassModal} />
        </Modal>
      );
    }
  }

  function modalAddClass() {
    setstateAddClass({
      ...stateAddClass,
      display: true,
    });
  }

  function closeAddClassModal() {
    setstateAddClass({
      ...stateAddClass,
      display: false,
    });
  }
  return (
    <Dashboard>
      <Button
        variant="contained"
        color="primary"
        style={{ margin: "20px", textTransform: "none" }}
        onClick={modalAddClass}
      >
        Add New Class
      </Button>
      {AddClassModalFunc()}
      <Container>
        <Grid container spacing={3}>
          {teachClasses.map((classData, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Paper elevation={3} style={{ padding: "16px" }}>
                <Typography variant="h5">{classData.name+"-"+classData.sectionName}</Typography>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => generateQrCode(classData._id)}
                        style={{ textTransform: "none" }}
                      >
                        Generate QR Code
                      </Button>
                      {showQR.show && (
                        <Modal closeModal={closeModal}>
                          {showQR.qr ? (
                            <QRCode
                              value={
                                "https://attendeaze.in/"+"markStudentAttendance?datetime=" +
                                currentTime.toString() +
                                "&class_id=" +
                                classId +
                                "&session_id=" +
                                sessionId.toString()
                              }
                              size={500}
                            />
                          ) : (
                            <h2>QR Not Found! 😕</h2>
                          )}
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => closeModal()}
                          >
                            End Attendance
                          </Button>
                          {/* {<h3>{currentTime}</h3>} */}
                        </Modal>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() => handleManualAttendance(classData._id)}
                        style={{ textTransform: "none" }}
                      >
                        Manual Attendance
                      </Button>
                    </Grid>
                    {/* <Grid item xs={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleGetSessions}
                    >
                      Get Sessions
                    </Button>
                  </Grid> */}
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        onClick={() =>
                          handleCumulativeAttendance(classData._id)
                        }
                        style={{ textTransform: "none" }}
                      >
                        Cummulative Attendance
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Dashboard>
  );
}
