import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import axios from "axios";
import toast from "react-hot-toast";

import Dashboard from "../../components/Dashboard/Dashboard";
import { loadingActions } from "../../store/loadingSlice";
import { URL } from "../../constants/backend";

const MarkAttendancePage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const classId = state.cId;
  const sessionId = state.sId;
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state

  const dispatch = useDispatch();

  const handleStatusChange = (event, rowIndex) => {
    const newData = [...tableData];
    newData[0].values[rowIndex].status = event.target.value;
    setTableData(newData);
  };

  const handleSave = async () => {
    // Handle saving the updated data here
    console.log(tableData);
    const res = await axios.put(
      URL+"/api/updateSession",
      { tableData, classId },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );

    if (res.status === 200) {
      navigate("/");
    }
  };

  const getSession = async () => {
    console.log("inside egt session data");
    console.log("class id is ", classId);
    const data = { classId, sessionId };
    console.log(data);
    dispatch(loadingActions.showLoading());
    const res = await axios.get(
      URL+"/api/getSession",
      { params: data },
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    dispatch(loadingActions.hideLoading());
    console.log("response data is ", res.data.data);
    if (res.status === 200) {
      setTableData(res.data.data);
      setIsLoading(false);
    } else {
      toast.error("Cannot fetch session data");
      setIsLoading(false);
    }
    console.log(res);
  };

  useEffect(() => {
    getSession();
  }, []);
  return (
    <Dashboard>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          <TableContainer
            component={Paper}
            style={{ maxHeight: "400px", overflowY: "scroll" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Roll No</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData[0].values.map((row, rowIndex) => (
                  <TableRow key={row._id.$oid}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.roll_no}</TableCell>
                    <TableCell>
                      <RadioGroup
                        value={row.status}
                        onChange={(e) => handleStatusChange(e, rowIndex)}
                        row
                      >
                        <FormControlLabel
                          value="P"
                          control={<Radio />}
                          label="Present"
                        />
                        <FormControlLabel
                          value="A"
                          control={<Radio />}
                          label="Absent"
                        />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box mt={2} display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
          </Box>
        </div>
      )}
    </Dashboard>
  );
};

export default MarkAttendancePage;
