import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/userSlice";
import axios from "axios";
import toast from "react-hot-toast";

import classes from "./Navbar.module.css";
import { URL } from "../../constants/backend";

function Navbar(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoutHandler = async () => {
    try {
      const res = await axios.get(URL+"/user/logout", {
        withCredentials: true,
      });
      toast.success(res.data.message);
      dispatch(userActions.setUser(null));
      navigate("/");
    } catch (err) {
      console.log("Logout Err:");
      console.log(err);
      toast.error("Something went wrong");
    }
  };

  return (
    <div
      className={`${classes.navigation} ${
        props.isActive ? classes.active : ""
      }`}
    >
      <ul>
        <li>
          <Link to="/">
            <i></i>
          </Link>
        </li>
        <li>
          <Link to="/">
            <span className={classes.icon}>
              <i className="fa-solid fa-house"></i>
            </span>
            <span className={classes.title}>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link onClick={logoutHandler}>
            <span className={classes.icon}>
              <i className="fa-solid fa-right-from-bracket"></i>
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
