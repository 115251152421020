import axios from "axios";
import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import Loader from "./components/Loader/Loader";
import AboutUs from "./pages/AboutUs/AboutUs";
import Info from "./pages/AboutUs/Info";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import ContactUs from "./pages/Contactus/ContactUs";
import EmailVerification from "./pages/EmailVerificationForm/EmailVerification";
import Home from "./pages/Home";
import Login from "./pages/Login/Login";
import MarkAttendancePage from "./pages/MarkAttendance/MarkAttendancePage";
import MarkStudentAttendance from "./pages/MarkStudentAttendance/MarkStudentAttendance";
import NewSessionPage from "./pages/NewSession/newSession";
import NoMatch from "./pages/NoMatch/NoMatch";
import OtpVerification from "./pages/OtpVerificationForm/OtpVerification";
import Profile from "./pages/Profile/Profile";
import QRCodeScanner from "./pages/QrScanner/QrScannerPage";
import Register from "./pages/Register/Register";
import SessionPage from "./pages/Sessions/sessionPage";
import StudentAttendancePage from "./pages/StudentAttendancePage/StudentAttendance";
import StudentDashboard from "./pages/StudentDashboard/StudentDashboard";
import TeacherDashboard from "./pages/TeacherDashboard/TeacherDashboard";

import CumulativeAttendancePage from "./pages/CumulativeAttendance/CumulativeAttendance";
import { loadingActions } from "./store/loadingSlice";
import { userActions } from "./store/userSlice";
import { URL } from "./constants/backend";

function App() {
  const dispatch = useDispatch();

  const getUserData = async () => {
    try {
      dispatch(loadingActions.showLoading());
      const res = await axios.get(
        URL+"/api/getCookieDetails",
        { withCredentials: true }
      );
      dispatch(loadingActions.hideLoading());
      if (res.data.success) {
        dispatch(userActions.setUser(res.data.data.user));
      } else {
        dispatch(userActions.setUser(null));
      }
    } catch (err) {
      console.log("App.js Error");
      console.log(err);
      dispatch(loadingActions.hideLoading());
    }
  };

  useEffect(() => {
    getUserData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const loading = useSelector((state) => state.loading.loading);
  const user = useSelector((state) => state.user.user);
  return (
    <>
      {loading && <Loader />}
      <Toaster position="top-center" />
      {user === null && (
        <React.Fragment>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/info" element={<Info />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register/otp-verify" element={<OtpVerification />} />
            {/* <Route path="/register"> */}
            <Route path="/register/verify" element={<EmailVerification />} />
            <Route path="/register/user" element={<Register />} />
            {/* </Route> */}

            <Route path="/contactus" element={<ContactUs />} />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </React.Fragment>
      )}
      {user?.userType === "student" && (
        <Routes>
          <Route path="/" element={<StudentDashboard />} />
          <Route path="/qrScanner" element={<QRCodeScanner />} />

          <Route
            path="/markStudentAttendance"
            element={<MarkStudentAttendance />}
          />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/info" element={<Info />} />
          <Route
            path="/seeAttendance/:cid"
            element={<StudentAttendancePage />}
          />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      )}
      {user?.userType === "admin" && (
        <Routes>
          <Route path="/" element={<AdminDashboard />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/info" element={<Info />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      )}
      {user?.userType === "teacher" && (
        <Routes>
          <Route path="/" element={<TeacherDashboard />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/info" element={<Info />} />
          <Route
            path="/attendance/:cid"
            element={<CumulativeAttendancePage />}
          />
          <Route path="*" element={<NoMatch />} />
          <Route path="/session/:cid" element={<SessionPage />} />
          <Route path="/newSession/:cid" element={<NewSessionPage />} />
          <Route path="/markAttendancePage" element={<MarkAttendancePage />} />
        </Routes>
      )}
    </>
  );
}

export default App;
