import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import axios from "axios";
import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import logo from "../../assets/logo.png";
import { URL } from "../../constants/backend";
import { loadingActions } from "../../store/loadingSlice";
import { userActions } from "../../store/userSlice";

import classes from "./Login.module.css";

const Login = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("Student");
  const [teacherPasswordOption, setTeacherPasswordOption] = useState(false);
  const [loginOption, setLoginOption] = useState(false);
  const [teacherOtpOption, setTeacherOtpOption] = useState(false);

  const handleLoginChange = () => {
    setLoginOption(true);
  };

  const handleOtpOptionChange = () => {
    setTeacherOtpOption(true);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setTeacherPasswordOption(false);
    setLoginOption(false);
    setTeacherOtpOption(false);
  };

  const handleTeacherPasswordOptionChange = () => {
    setTeacherPasswordOption(true);
    handleLoginChange();
  };
  const email_ref = useRef();
  const pass_ref = useRef();
  const navigate = useNavigate();

  const [inpVal, setVal] = useState({
    email: "",
    pass: "",
  });
  const [inpValid, setInpValid] = useState({
    email: false,
    pass: false,
  });

  const checkEmail = (event) => {
    setVal({
      ...inpVal,
      email: email_ref.current.value,
    });
    let currVal = email_ref.current.value;
    if (!currVal.includes("@fms.edu")) {
      setInpValid({
        ...inpValid,
        email: false,
      });
    } else {
      setInpValid({
        ...inpValid,
        email: true,
      });
    }
  };

  const checkPass = (event) => {
    setVal({
      ...inpVal,
      pass: pass_ref.current.value,
    });
    let currVal = pass_ref.current.value;
    if (currVal.length === 0) {
      setInpValid({
        ...inpValid,
        pass: false,
      });
    } else {
      setInpValid({
        ...inpValid,
        pass: true,
      });
    }
  };

  const googleAuth = async (e) => {
    try {
      window.open("https://api.attendeaze.in/user/google/callback", "_self");
    } catch (err) {
      console.log(err);
    }
  };
  const submitHandler = async (e) => {
    try {
      e.preventDefault();

      // if (!inpValid.email || !inpValid.pass) {
      //   toast("Please fill the form correctly");
      //   return;
      // }

      const email = inpVal.email;
      const password = inpVal.pass;

      dispatch(loadingActions.showLoading());

      const res = await axios.post(
        URL + "/user/login",
        {
          email,
          password,
        },
        { withCredentials: true }
      );

      dispatch(loadingActions.hideLoading());

      if (res.data.success) {
        toast.success(res.data.message);
        dispatch(userActions.setUser(res.data.data));
        navigate("/");
      } else {
        toast.error(res.data.message);
      }
    } catch (err) {
      console.log("Error = " + err);
      dispatch(loadingActions.hideLoading());
      toast.error("Something went wrong!");
    }
  };

  let emailErr = () => {
    if (inpVal.email.includes("@") && !inpVal.email.includes("@fms.edu")) {
      return <p>Only fms.edu domain allowed</p>;
    } else if (!inpVal.email.includes("@") && inpVal.email.length !== 0) {
      return <p>Invalid Email Format</p>;
    }
  };

  return (
    <>
      <div className="brand" style={{ marginTop: "1rem" }}>
        <div className="brand--logo">
          <Link to="/">
            <img src={logo} alt="Logo here" />
          </Link>
          <Link to="/">Attend-Eaze</Link>
        </div>
      </div>
      <div className={classes.center}>
        <form className={classes.form} onSubmit={submitHandler}>
          <h4>Login</h4>
          <FormControl component="fieldset" className="radio-button-group">
            <FormLabel component="legend">Select an Option:</FormLabel>
            <RadioGroup
              row
              aria-label="option"
              name="option"
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <FormControlLabel
                value="Student"
                control={<Radio />}
                label="Student"
              />
              <FormControlLabel
                value="Teacher"
                control={<Radio />}
                label="Teacher"
              />
            </RadioGroup>
          </FormControl>
          {selectedOption === "Teacher" && teacherPasswordOption === true && (
            <div className={classes.txt_field}>
              <input
                ref={email_ref}
                onChange={checkEmail}
                value={inpVal.email}
                className={`${inpValid.email ? classes.right : classes.wrong}`}
                type="text"
                required
              />
              <label>Email</label>
              {/* {emailErr()} */}
            </div>
          )}
          {selectedOption === "Teacher" && teacherPasswordOption === true && (
            <div className={classes.txt_field}>
              <input
                ref={pass_ref}
                onChange={checkPass}
                value={inpVal.pass}
                className={`${inpValid.pass ? classes.right : classes.wrong}`}
                type="password"
                required
              />
              <label>Password</label>
            </div>
          )}
          {selectedOption === "Teacher" && teacherOtpOption === true && (
            <div className={classes.txt_field}>
              <input
                ref={email_ref}
                // onChange={checkEmail}
                value={inpVal.email}
                className={`${inpValid.email ? classes.right : classes.wrong}`}
                type="text"
                required
              />
              <label>Email</label>
              {/* {emailErr()} */}
            </div>
          )}
          {selectedOption === "Teacher" && teacherOtpOption === true && (
            <div className={classes.txt_field}>
              <input
                ref={pass_ref}
                onChange={checkPass}
                value={inpVal.pass}
                className={`${inpValid.pass ? classes.right : classes.wrong}`}
                type="text"
                required
              />
              <label>Password</label>
            </div>
          )}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            // You can adjust the height as needed
          >
            {selectedOption === "Teacher" && loginOption && (
              <Button
                variant="contained"
                color="primary"
                onClick={submitHandler}
              >
                Login
              </Button>
            )}
          </Box>
          {selectedOption === "Teacher" && teacherOtpOption === true && (
            <input type="submit" value="Send OTP" onClick={submitHandler} />
          )}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            // You can adjust the height as needed
          >
            {selectedOption === "Student" && (
              <Button variant="contained" color="primary" onClick={googleAuth}>
                Login With Google
              </Button>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            // You can adjust the height as needed
          >
            {selectedOption === "Teacher" &&
              !teacherPasswordOption &&
              !teacherOtpOption && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleTeacherPasswordOptionChange}
                >
                  Login With Password
                </Button>
              )}
          </Box>
        </form>
      </div>
    </>
  );
};

export default Login;
