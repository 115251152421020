import { Link } from "react-router-dom";
import { useInView } from 'react-intersection-observer';

import Footer from "../components/Footer";
import Homenav from "../components/Homenav";
import '../css/home.css'
// import Loader from "../components/Loader/Loader";

import pic1 from "../assets/attendance.png"
import fet1 from "../assets/middle_image.png"
import fet2 from "../assets/undraw_Happy_news.png"
import fet3 from "../assets/icon8.png"
import fet4 from "../assets/QR_Code_pic.png"
import endimg from "../assets/productivity_pic.png"

export default function Home() {
    return (
        <>
            <Homenav />
            <main>
                <div className="back-to-top">
                </div>
                <Section1 />
                <hr />
                {/* <Section2 /> */}
                <Section3 />
            </main>
            <Footer />
        </>
    )
}


function Section1() {

    const { ref: ref1, inView: view1 } = useInView();
    const { ref: ref2, inView: view2 } = useInView();
    const { ref: ref3, inView: view3 } = useInView();

    return (
        <>
            <section className="page-1">
                <div ref = {ref1} className={`content from_left slide_in ${view1? "appear":""}`}>
                    <h1>Elevate Your Classroom Experience!</h1>
                    <p>AttendEaze streamlines attendance management effortlessly. Unlock the power of seamless tracking for students and teachers alike!</p>
                    <Link to="/register/verify" id="register-btn-2" sizes="16x16">Register</Link>
                </div>
                <div ref = {ref2} className={`student-image from_right slide_in ${view2? "appear":""}`}>
                    <img src={pic1} alt="" />
                </div>
                <div ref = {ref3} className={`extra_buttons fade_in ${view3? "appear":""}`}>
                    <Link to ="/login" id="login">Login</Link>
                    <Link to ="/register/verify" id="register">Register</Link>
                </div>
            </section>
        </>
    )
}

function Section2() {
    const { ref: ref1, inView: view1 } = useInView();
    const { ref: ref2, inView: view2 } = useInView();

    return (
        <>
            <section className="page-2">
                <div ref = {ref1} className={`image from_left slide_in ${view1? "appear":""}`}>
                    <img src={fet1} alt="" />
                </div>
                {/* <div ref = {ref2} className={`content from_right slide_in slow ${view2? "appear":""}`}>
                    <p>
                    AttendEase is an automated system for taking attendance which provides a simple and reliable system to take attendance.
                    </p>
                </div> */}
            </section>
        </>
    )
}

function Section3() {
    const { ref: ref1, inView: view1 } = useInView();
    const { ref: ref2, inView: view2 } = useInView();
    const { ref: ref3, inView: view3 } = useInView();
    const { ref: ref4, inView: view4 } = useInView();

    return (
        <>
            <section className="page-3">
                <div className="features">
                    <div ref = {ref1} className={`feature from_right slide_in ${view1? "appear":""}`} id="feature-3">
                        <img src={fet3} alt="" />
                        <p>Completely Automated Attendance</p>
                    </div>
                    <div ref = {ref2} className={`feature from_left slide_in ${view2? "appear":""}`} id="feature-2">
                        <img src={fet2} alt="" />
                        <p>Easy to use</p>
                    </div>
                    <div ref = {ref3} className={`feature from_right slide_in ${view3? "appear":""}`} id="feature-1">
                        <img src={fet4} alt="" />
                        <p>QR code authentification</p>
                    </div>
                </div>
                <div ref = {ref4} className={`left_img from_right slide_in ${view4? "appear":""}`}>
                    <img src={endimg} alt="" />
                </div>
            </section>
        </>
    )
}