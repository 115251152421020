import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import classes from "./OtpVerification.module.css";
import { Button } from '@mui/material';


const OtpVerification = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const state_otp = state.otp;
  const [otp, setOtp] = useState("");
  function tempAlert(msg, duration) {
    var el = document.createElement("div");
    el.setAttribute(
      "style",
      "position:absolute;top:10%;left:20%;background-color:white;"
    );
    el.innerHTML = msg;
    setTimeout(function () {
      el.parentNode.removeChild(el);
    }, duration);
    document.body.appendChild(el);
  }
  const logData = () => {
    console.log("data from email is ");
    if (otp.match(state_otp)) {
      navigate("/register/user", { state: { email: state.email } });
      console.log(true);
    } else {
      alert("Entered OTP is incorrect", 3000);
      setOtp("");
      console.log(false);
    }
    console.log("to check otp data");
    console.log(otp);
  };
  return (
    <>
      <div className="brand" style={{ marginTop: "1rem" }}>
        <div className="brand--logo">
          <Link to="/">
            <img src={logo} alt="Logo here" />
          </Link>
          <Link to="/">Attend-Eaze</Link>
        </div>
      </div>
      <div className={classes.center}>
        <h4 >Otp Verification</h4>
        <div cl>
          <OtpInput
            inputStyle={classes.inputStyle}
            value={otp}
            onChange={setOtp}
            numInputs={4}
            renderSeparator={<span>-</span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>

        <Button style= {{marginTop:"20px" ,textTransform:"none"}} variant="contained" onClick={logData}>
      Verify OTP
    </Button>
      </div>
    </>
  );
};

export default OtpVerification;
