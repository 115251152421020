import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Dashboard from "../../components/Dashboard/Dashboard";
import Card from "../../components/SessionCard/SessionCard";
import { URL } from "../../constants/backend";
import { loadingActions } from "../../store/loadingSlice";
import classes from "./sessionPage.module.css";

function SessionPage() {
  const [sessions, setSessions] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cid } = useParams();
  const [className, setClassName] = useState("");
  const newSessions = [];

  console.log("class id is", cid);
  const getSessions = async () => {
    try {
      dispatch(loadingActions.showLoading());
      const res = await axios.get(
        URL+"/api/getSessions/" + cid,
        {
          withCredentials: true,
        }
      );
      dispatch(loadingActions.hideLoading());
      setClassName(res.data.data.name);
      res.data.data.attendance.forEach((attend) => {
        let stud_len = attend.values.length;
        let present_counter = 0;
        attend.values.forEach((stud_attend) => {
          if (stud_attend.status === "P") {
            present_counter = present_counter + 1;
          }
        });
        newSessions.push({
          session_name: attend.date,
          session_present: present_counter,
          session_students: stud_len,
          session_present_percentage: (present_counter / stud_len) * 100,
        });
      });
      setSessions([...sessions, ...newSessions]);
    } catch (error) {
      dispatch(loadingActions.hideLoading());
      console.log("Error in fetching Classes:");
      console.log(error);
    }
  };

  const newSessionPage = () => {
    const url = "/newSession/" + cid;
    navigate(url);
  };

  useEffect(() => {
    getSessions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  console.log(sessions);
  return (
    <Dashboard>
      <button onClick={newSessionPage}>Add New Session</button>
      <div className={classes.classes}>
        <div className={classes.addsession}>
          {sessions.map((c) => {
            console.log("set current class", c);
            return (
              <Card
                sessionName={c.session_name}
                sessionPresence={c.session_present}
                sessionCount={c.stud_len}
                sessionPecentage={c.session_present_percentage}
              />
            );
          })}
        </div>
      </div>
    </Dashboard>
  );
}

export default SessionPage;
