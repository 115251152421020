import { Button, Paper, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Dashboard from "../../components/Dashboard/Dashboard";
import { URL } from "../../constants/backend";
import { loadingActions } from "../../store/loadingSlice";

export default function StudentDashboard() {
  const navigate = useNavigate();

  const [groupData, setGroupData] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [studentAttendanceArray, setStudentAttendanceArray] = useState([]);

  const dispatch = useDispatch();

  function calculatePresentPercentage(attendanceData, studentEmail) {
    let attendance_array = [];

    attendanceData.forEach((session) => {
      const { session_name } = session; // Destructure the session_name from session
      const values = session.values.filter(
        (value) => value.email_id === studentEmail
      );

      // Use a functional update to accumulate new values in a temporary array
      setStudentAttendanceArray((prevAttendanceArray) => [
        ...prevAttendanceArray,
        ...values.map((obj) => ({ ...obj, session_name })),
      ]);
    });
    console.log("studentAttendanceArray", studentAttendanceArray);

    // Filter attendance data for the specific student using their email_id
    const studentAttendance = attendanceData.filter((session) =>
      session.values.some((value) => value.email_id === studentEmail)
    );
    // Calculate the total number of sessions where the student was marked as 'P'
    const totalPresentSessions = studentAttendance.reduce((count, session) => {
      attendance_array = session.values.filter(
        (value) => value.email_id === studentEmail
      );
      count += session.values.filter(
        (value) => value.email_id === studentEmail && value.status === "P"
      ).length;

      return count;
    }, 0);

    // Calculate the total number of attendance sessions for that student
    const totalAttendanceSessions = studentAttendance.length;

    // Calculate the present percentage
    const presentPercentage = (
      (totalPresentSessions / totalAttendanceSessions) *
      100
    ).toFixed(2);

    return presentPercentage;
  }

  const getClasses = async () => {
    try {
      dispatch(loadingActions.showLoading());
      const res = await axios.get(URL+"/api/getClasses", {
        withCredentials: true,
      });
      dispatch(loadingActions.hideLoading());
      const temp = res.data.data;
      const student_email = res.data.email;

      const groupedData = {};
      temp.forEach((item) => {
        const className = item.name + "-" + item.sectionName;
        const attendance_percentage = calculatePresentPercentage(
          item.attendance,
          student_email
        );
        item["attendance_percentage"] = attendance_percentage;
        if (!groupedData[className]) {
          groupedData[className] = [];
        }
        groupedData[className].push(item);
      });
      setGroupData(groupedData);
      console.log("group data is ", groupData);
      setIsLoading(false);
    } catch (error) {
      dispatch(loadingActions.hideLoading());
      console.log("Error in fetching Classes:");
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleSeeAttendance = (class_item) => {
    console.log("class is ", class_item._id);
    navigate("/seeAttendance/" + class_item._id, {
      state: { class_id: class_item._id },
    });
  };

  const handleScanQRCode = () => {
    navigate("/qrScanner");
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      // Make your API call here using the current value of count
      getClasses();
    }, 500); // Adjust the delay (in milliseconds) as needed

    // Clean up the timer if count changes before it fires
    return () => clearTimeout(timer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dashboard>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div>
          {Object.keys(groupData).map((className, index) => (
            <div key={index} style={{ display: "flex", flexWrap: "wrap" }}>
              {groupData[className].map((classItem, itemIndex) => (
                <React.Fragment key={classItem._id.$oid}>
                  <Paper
                    elevation={3}
                    style={{ margin: "16px", padding: "16px", display: "flex" }}
                  >
                    <div style={{ flex: "1" }}>
                      <Typography variant="h5" style={{ fontWeight: "bold" }}>
                        {className}
                      </Typography>
                      <Typography variant="subtitle1">
                        Attendance is {classItem.attendance_percentage} %
                      </Typography>
                      {/* Add "See Attendance" and "Scan QR Code" buttons */}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSeeAttendance(classItem)}
                        style={{ marginRight: "8px" }}
                      >
                        See Attendance
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleScanQRCode()}
                      >
                        Scan QR Code
                      </Button>
                    </div>
                  </Paper>
                  {/* Add spacing or margin between the pairs of containers */}
                  {(itemIndex + 1) % 2 === 0 && (
                    <div
                      style={{
                        flexBasis: "16px" /* Adjust the width as needed */,
                        height: 0,
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      )}
    </Dashboard>
  );
}
