import Dashboard from "../../components/Dashboard/Dashboard";
import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Modal from "../../components/Modal/Modal.jsx";
import QRCode from "react-qr-code";
import { Button, Box, Container } from "@mui/material";
import { URL } from "../../constants/backend";

function NewSessionPage() {
  const { cid } = useParams();
  const [showQR, setShowQr] = useState({
    show: false,
    qr: undefined,
  });
  const [sessionId, setSessionId] = useState("");

  const generateQrCode = async () => {
    console.log("test");
    const res = await axios.get(
      URL+"/auth/generateQrCode/" + cid,
      {
        withCredentials: true,
      }
    );
    console.log(res.data);
    if (res.status === 200) {
      if (res.data.success === true) {
        let qrString = cid;
        setShowQr({
          show: true,
          qr: qrString,
        });
        setSessionId(res.data.data);
        toast.success("QR generated");
        console.log("QR generated");
        console.log("data is", res);
      } else {
        toast.error("Cannot generate QR");
      }
    } else {
      toast.error("Some internal error occurred. Please try again.");
    }
  };

  const [currentTime, setCurrentTime] = useState(Date.now());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 10000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);
  const closeModal = () => {
    setShowQr({
      show: false,
      qr: "",
    });
  };

  return (
    <Dashboard>
      <Container style={{ height: "50vh" }} maxWidth="xs">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="100%"
        >
          <Button
            style={{
              backgroundColor: "blue",
              color: "white",
              marginBottom: "15px",
              width: "500px",
            }}
            onClick={generateQrCode}
          >
            Project Dynamic QR
          </Button>
          {showQR.show && (
            <Modal closeModal={closeModal}>
              {showQR.qr ? (
                <QRCode
                  value={
                    "https://attendeaze.in/"+"test?datetime=" +
                    currentTime.toString() +
                    "&class_id=" +
                    cid.toString() +
                    "&session_id=" +
                    sessionId.toString()
                  }
                />
              ) : (
                <h2>QR Not Found! 😕</h2>
              )}
              {<button onClick={closeModal}>End</button>}
              {<h3>{currentTime}</h3>}
              {<p>{cid.toString()}</p>}
            </Modal>
          )}
          <Button
            style={{
              backgroundColor: "blue",
              color: "white",
              marginBottom: "15px",
              width: "500px",
            }}
          >
            Mark Attendance Manually
          </Button>
        </Box>
      </Container>
    </Dashboard>
  );
}

export default NewSessionPage;
