import React,{useState, useEffect} from "react";
import { useLocation } from "react-router-dom";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import axios from "axios";
import Dashboard from "../../components/Dashboard/Dashboard";
import { URL } from "../../constants/backend";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";



export default function StudentAttendance() {

  const { state } = useLocation();
  const { cid } = useParams();
  const [classData, setClassData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const [studentAttendanceArray, setStudentAttendanceArray] = useState([]);


  // const attendance_array = state.attendance;
  const user = useSelector((state) => state.user.user);

  console.log("user is ", user.email);

  function calculatePresentPercentage(attendanceData, studentEmail) {
    setStudentAttendanceArray([]);
    let attendance_array = [];
    let prevAttendanceArray = [];

    attendanceData.forEach((session) => {
      const { session_name } = session; // Destructure the session_name from session
      const values = session.values.filter(
        (value) => value.email_id === studentEmail
      );

      // Use a functional update to accumulate new values in a temporary array
      setStudentAttendanceArray((prevAttendanceArray) => [
        ...prevAttendanceArray,
        ...values.map((obj) => ({ ...obj, session_name })),
      ]);
    });
    console.log("studentAttendanceArray", studentAttendanceArray);
  }

  const getClasses = async () => {
    // dispatch(loadingActions.showLoading())
    console.log("class id is ", state.classId);
    const res = await axios.get(
      URL+"/api/getSessionsData/" + cid,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    // dispatch(loadingActions.hideLoading());
    console.log("response data is ", res.data.data.attendance);
    if (res.status === 200) {
      setClassData(res.data.data.attendance);
      console.log("attendance data is ",res.data.data.attendance)
      const attendance_percentage = calculatePresentPercentage(
        res.data.data.attendance,
        user.email
      );
      setIsLoading(false);
    } else {
      toast.error("Cannot fetch session data");
      setIsLoading(false);
    }
    console.log(res);
  };

  useEffect(() => {
    getClasses();
  },[]);

  return (
    <Dashboard>
      <div style={{ overflow: "auto", maxHeight: "500px" }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentAttendanceArray.map((item, index) => {
                const date = item.session_name;
                const status = item.status;
                return (
                  <TableRow key={index}>
                    <TableCell>{date}</TableCell>
                    <TableCell>{status}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Dashboard>
  );
}
